const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  
const referal = params.referal || null;

function enterListen(event){
        if (event.key === "Enter") {
          event.preventDefault();
          document.getElementById("continue").click();
        }
}
function email_login() {
    $("#password").slideDown("fast", function() {})
    $("#manual_form").slideUp("fast", function() {})
    $("#email")[0].removeEventListener("keypress", enterListen);
    $("#password")[0].addEventListener("keypress", enterListen);
    $("#password")[0].value = "";
    $("#label")[0].innerText = "or login with e-mail"
    $("#continue")[0].innerText = "Login"
    $("#continue")[0].onclick = function() {
        if (isValidEmail($("#email")[0].value)){
            window.sendEmailLogin( $("#email")[0].value , $("#password")[0].value )
        }
        else{
            picoModal("Invalid e-mail").show();
        }
    };
    $("#manual_form").slideDown("fast", function() {})
}

function email_register() {
    $("#password").slideDown("fast", function() {})
    $("#manual_form").slideUp("fast", function() {})
    $("#email")[0].removeEventListener("keypress", enterListen);
    $("#password")[0].addEventListener("keypress", enterListen);
    $("#password")[0].value = "";
    $("#label")[0].innerText = "or register with e-mail"
    $("#continue")[0].innerText = "Register"
    $("#continue")[0].onclick = function() {
        if (isStrongPassword($("#password")[0].value) && isValidEmail($("#email")[0].value)){
            fetch('http://127.0.0.1:8080/register', { 
                method: 'POST', 
                body:JSON.stringify({email: $("#email")[0].value, password: $("#password")[0].value, referal:referal}), 
                headers: {'Content-Type': 'application/json'
            } })
                .then(response => response.text())
                .then(data => { 
                    if ( data == "OK" ){
                        window.sendEmailLogin( $("#email")[0].value , $("#password")[0].value )
                    }
                else if (data == "<WEAK_PWD>"){
                        picoModal("Weak password. Try 8 characters, mixed caps, and a number.").show();
                    }
                    else {
                        picoModal("Registration failed. Account may already exsist.").show();
                    }
                 });
                
            }
        
    else if (isValidEmail($("#email")[0].value)){
        picoModal("Weak password. Try 8 characters, mixed caps, and a number.").show();
    }
    else {
        picoModal("Invalid e-mail").show();
    }
}
    $("#manual_form").slideDown("fast", function() {})
}

function email_forgot() {
    $("#label")[0].innerText = "or recover your password"
    $("#continue")[0].innerText = "Send recovery e-mail"
    $("#email")[0].addEventListener("keypress", enterListen);
    $("#password")[0].removeEventListener("keypress", enterListen);
    $("#password")[0].value = "";
    $("#continue")[0].onclick = function() {
        
        picoModal("Password reset e-mail sent. Check your inbox for directions.").show();
    };
    $("#manual_form").slideDown("fast", function() {
        $("#password").slideUp("fast", function() {})
    })
}

function isStrongPassword(password) {
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const isOver8Characters = password.length > 8;
    
    return hasUppercase && hasLowercase && hasNumber && isOver8Characters;
  }
function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

document.getElementById("email_login").addEventListener("click", email_login)
document.getElementById("email_register").addEventListener("click", email_register)
document.getElementById("email_forgot").addEventListener("click", email_forgot)
